<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Редактирование TOS
            </VToolbarTitle>
          </VToolbar>
          <VCardText>
            <VRow>
              <VCol cols="6">
                <h6 class="text-h6">
                  Компания: <span class="font-weight-bold">{{ companyName }}</span>
                </h6>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <VTextarea
                  v-model="text"
                  filled
                  label="Текст TOS"
                />
              </VCol>
            </VRow>

            <VRow>
              <VCol cols="6">
                <VCheckbox
                  v-model="enabled"
                  label="Активность TOS"
                  hint="Изменение TOS сохраняется при изменении"
                  persistent-hint
                  @click="toggleEnabled"
                />
              </VCol>
            </VRow>
          </VCardText>

          <VCardActions>
            <VBtn
              color="primary"
              depressed
              large
              @click="saveText"
            >
              Сохранить
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'TosEdit',

  inject: ['Names'],

  data() {
    return {
      text: 'html',
      companyName: '',
      enabled: false,
    };
  },

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
  },

  async created() {
    await this.getTos();
    await this.getCompany();
  },

  methods: {
    async getTos() {
      try {
        const response = await this.$di.api.Talent.tosConfigGet({ companyId: this.companyId });

        this.text = response.text || '';
        this.enabled = response.enabled || false;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async getCompany() {
      try {
        const response = await this.$di.api.Account.getCompany({ id: this.companyId });

        this.companyName = response.company.name || 'нет названия';
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async saveText() {
      const payload = {
        companyId: this.companyId,
        text: this.text,
      };

      try {
        await this.$di.api.Talent.tosConfigUpdate(payload);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async toggleEnabled() {
      const payload = {
        companyId: this.companyId,
      };

      await this.$di.api.Talent.tosConfigToggle(payload);
    },
  },
};
</script>
